const FleetPage = ({ data }) => {
  const hero = data.fleetYaml.hero
  const equipment = data.fleetYaml.equipment
  const safety = data.fleetYaml.safety
  const contact = data.contactYaml
  const serviceList = data.allServiceListYaml.edges

  // Remove Asphalt from the list of services shown on the Contact form
  const contactServiceList = serviceList.filter(
    service => service.node.heading !== 'Asphalt Maintenance'
  )

  return (
    <Base>
      <PageMetadata page={data.site.siteMetadata.fleetPage} />

      <main id="main-content" tabIndex="-1" className="mukta dark-gray">
        <Hero
          title={hero.title}
          tagline={hero.tagline}
          link={hero.link}
          image={hero.image}
        />

        <Equipment
          heading={equipment.heading}
          paragraph={equipment.paragraph}
          fleet={equipment.fleet}
        />

        <Safety heading={safety.heading} details={safety.details} />

        <Contact
          stamp={contact.stamp}
          heading={contact.heading}
          invitation={contact.invitation}
          services={contactServiceList}
          successMessage={contact.successMessage}
        />
      </main>
    </Base>
  )
}

/*
 *
 * Queries
 * 
 */

export const query = graphql`
  query {
    site {
      siteMetadata {
        fleetPage {
          description
          title
          url
        }
      }
    }
    fleetYaml {
      hero {
        title {
          white
          yellow
        }
        tagline
        link {
          href
          text
        }
        image {
          file {
            childImageSharp {
              fluid(maxWidth: 3600) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          alt
          objPosition
        }
      }
      equipment {
        heading {
          black
          yellow
        }
        paragraph
        fleet {
          heading
          machines {
            number
            name
          }
        }
      }
      safety {
        heading {
          black
          yellow
        }
        details {
          heading
          paragraph
        }
      }
    }
    contactYaml {
      stamp {
        largeText
        smallText
      }
      heading
      invitation
      successMessage
    }
    allServiceListYaml {
      edges {
        node {
          heading
        }
      }
    }
  }
`

/*
 *
 * Imports & Exports
 * 
 */

import React from 'react'
import { graphql } from 'gatsby'

import Base from '../components/Base'
import PageMetadata from '../components/PageMetadata'
import Hero from '../components/Hero'
import Equipment from '../sections/FleetEquipment'
import Safety from '../sections/FleetSafety'
import Contact from '../sections/Contact'

export default FleetPage
