const Equipment = ({ heading, paragraph, fleet }) => (
  <section className="container pv5 sm:mv3 md:mv4 lg:mv5">
    <h2 className="section-heading mb4 sm:pb3" style={{ maxWidth: `14ch` }}>
      <span dangerouslySetInnerHTML={{ __html: heading.black }} />
      <span className="db yellow">{heading.yellow}</span>
    </h2>

    <p className="mb5 measure-wide lh-copy">{paragraph}</p>

    <Fleet fleet={fleet} />
  </section>
)

const Fleet = ({ fleet }) => (
  <>
    <h2 className="section-subheading mb4">{fleet.heading}</h2>

    <ul>
      {fleet.machines.map(machine => (
        <li key={machine.name} className="heading f4 ttl">
          <span className="yellow">
            {machine.number}
            &nbsp;
          </span>
          {machine.name}
        </li>
      ))}
    </ul>
  </>
)

/*
 *
 * Imports & Exports
 * 
 */

import React from 'react'

export default Equipment
