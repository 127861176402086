const Safety = ({ heading, details }) => (
  <section className="bg-near-white pv5">
    <div className="container sm:pv3 md:pv4 lg:pv5">
      <h2 className="section-heading mb4 sm:pb3">
        {heading.black}
        <br />
        <span className="db yellow">{heading.yellow}</span>
      </h2>

      {details.map((detail, i) => (
        <Fragment key={i}>
          {detail.heading && (
            <h3 className="heading mt4 sm:pt3 f4 sm:f3">{detail.heading}</h3>
          )}
          <p className="mt3 sm:pt2 measure-wide lh-copy">{detail.paragraph}</p>
        </Fragment>
      ))}
    </div>
  </section>
)

/*
 *
 * Imports & Exports
 * 
 */

import React, { Fragment } from 'react'

export default Safety
